import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import styles from "./ShopifyFinalize.module.scss";
import dialogStyles from "./Dialog.module.scss";

const ShopifyErrorDialog = ({ open, close, title, text }) => {
  return (
    <Dialog
      classes={{ paper: dialogStyles.cDialog }}
      style={{ textAlign: "left" }}
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={dialogStyles.Contentdiv}>
        <div
          className={styles.timeoutLeft}
          style={{ textAlign: "left", marginLeft: "40px !important" }}
        >
          {title}
        </div>
        <div style={{ textAlign: "left" }} className={dialogStyles.store}>
          {text}
        </div>
        <div className={dialogStyles.buttonsDiv}>
          <button className={dialogStyles.getstarted} onClick={close}>
            Retry
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ShopifyErrorDialog;
