import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import { TextField } from "@material-ui/core";

import styles from "./ShopifyModal.module.scss";
import dialogStyles from "./Dialog.module.scss";

const ShopifyModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [shopName, setShopName] = useState("");

  const onChange = (event) => {
    setShopName(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const trimmed = shopName.trim();
    onClose();
    if (trimmed.endsWith(".myshopify.com")) {
      navigate(`/register?shop=${trimmed}`);
    }

    return navigate(`/register?shop=${trimmed}.myshopify.com`);
  };

  return (
    <Dialog
      classes={{ paper: dialogStyles.cDialog }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={onSubmit}>
        <div className={dialogStyles.Contentdiv}>
          <div className={styles.modalHeader}>
            On which Shopify store would you like to install fomopromo?
          </div>
          <div className={styles.shopName}>
            <TextField
              value={shopName}
              onChange={onChange}
              label="Shop Name"
              fullWidth={true}
              variant="outlined"
            />
          </div>
          <div className={styles.buttonsDiv}>
            <button type="button" onClick={onClose} className={styles.close}>
              Cancel
            </button>
            <input
              disabled={shopName.trim().length < 1}
              type="submit"
              className={styles.submit}
              value="Submit"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ShopifyModal;
