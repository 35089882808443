import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../hooks";

import createApp from "@shopify/app-bridge";

import { isIframe } from "../utils";
import { RootContext } from "../context";
import { authenticateWithShopify } from "../graphQL";
import ShopifyErrorDialog from "./ShopifyErrorDialog";
import ShopifyLoader from "./ShopifyLoader";

const ShopifyFinalize = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const ctx = useContext(RootContext);
  const { setShopifyHost, setShopifyConnectFlag } = ctx;

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(true);

  const shop = query.get("shop");
  const code = query.get("code");
  const hmac = query.get("hmac");
  const host = query.get("host");

  const close = () => {
    navigate(`/register?shop=${shop}`);
  };

  useEffect(() => {
    if (shop === null || code === null || hmac === null) {
      setLoading(false);
      setError({
        title: "Missing Parameters",
        message: "Shopify parameters were not returned in the query string",
      });
      return;
    }

    (async () => {
      // Cannot fetch token from iframe, should redirect
      // to session auth
      if (isIframe()) {
        navigate(`/register?${query.toString()}`);
        return;
      }

      try {
        const { brandExists = false } = await authenticateWithShopify(
          query.toString()
        );
        setShopifyConnectFlag(brandExists);

        if (!host) {
          setLoading(false);
          setError({
            title: "Unknown Error",
            text: "Something went wrong installing the app in your Shopify store.",
          });
          return;
        }

        // Shopify host will have likely been set by the registration component but just to make sure
        // we do this before redirecting in order to redirect back to the admin menu.
        setShopifyHost(host);
        createApp({
          apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
          host,
          forceRedirect: true,
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
        const { errorInfo, message } = e?.errors?.[0] || {};
        const { nonFieldErrors } = errorInfo || {};
        const title = "Authentication Error";
        let text = message || "";
        if ((nonFieldErrors?.length || 0) > 0) {
          text = nonFieldErrors.join(" ,");
        } else if (nonFieldErrors) {
          text = nonFieldErrors[0];
        }
        setError({ title, text });
        return;
      }
    })();
  }, [
    code,
    hmac,
    host,
    navigate,
    query,
    setShopifyConnectFlag,
    setShopifyHost,
    shop,
  ]);

  return (
    <>
      {loading ? <ShopifyLoader /> : null}
      <ShopifyErrorDialog
        title={error?.title}
        text={error?.text}
        open={error?.title || false}
        close={close}
      />
    </>
  );
};

export default ShopifyFinalize;
