import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Popout from "react-popout-v2";
import { RootContext } from "../context";
import {
  authenticateShopifySessionToken,
  diconnectShopify,
  getChannelId,
  getIntegrationStatus,
} from "../graphQL";

import { Redirect } from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge-utils";
import {
  AppProvider,
  Avatar,
  Banner,
  Button,
  Page,
  Card,
  EmptyState,
  FooterHelp,
  Frame,
  Layout,
  Loading,
  Link,
  Modal,
  Stack,
  Toast,
  TextContainer,
  CalloutCard,
} from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";

const ShopifyAccount = ({ shop, brand, appBridge, loading }) => {
  const { shopifyConnectFlag: isConnected, setShopifyConnectFlag } =
    useContext(RootContext);
  const [connectedBrand, setConnectedBrand] = useState(null);
  const [accountConnecting, setAccountConnecting] = useState(false);
  const [connectFailed, setConnectFailed] = useState(false);
  const [verifyCheckFailed, setVerifyCheckFailed] = useState(false);
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [disconnectFailed, setDisconnectFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(loading);
  const [{ products, collections }, setProductCounts] = useState({
    products: 0,
    collections: 0,
  });
  const [channelId, setChannelId] = useState(null);
  const popupRef = useRef(null);

  console.log("Environment: ", process.env);

  useEffect(() => {
    if (brand) {
      setConnectedBrand(brand);
    }
  }, [brand]);

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  useEffect(() => {
    (async () => {
      if (appBridge) {
        const sessionToken = await getSessionToken(appBridge);
        let newChannelId;
        try {
          newChannelId = await getChannelId(sessionToken, false);
        } catch (e) {
          console.error(e);
        }
        if (newChannelId) {
          setChannelId(newChannelId);
        }
        let status;
        try {
          status = await getIntegrationStatus(sessionToken, false);
        } catch (e) {
          console.error(e);
        }
        if (status) {
          setProductCounts(status);
        }
      }
    })();
  }, [appBridge]);

  const onAccountConnectionOpen = async () => {
    // if (await checkShopVerified(shop, false)) {
    setAccountConnecting(true);
    // } else {
    //   setVerifyCheckFailed(true);
    // }
  };

  const onSelectProducts = () => {
    const fields = ["variants.sku", "variants.price"];
    if (channelId) {
      fields.push(`publications.${channelId}.published_at`);
    }
    const productsUrl = `/bulk?resource_name=Product&edit=${fields.join(",")}`;
    return Redirect.create(appBridge).dispatch(Redirect.Action.ADMIN_PATH, {
      path: productsUrl,
    });
  };

  const onAccountConnectionCreate = (windowHandle) => {
    popupRef.current = windowHandle;
    setTimeout(() => checkConnectionWindowClose(windowHandle), 6000);
  };

  const checkConnectionWindowClose = async (windowHandle) => {
    // Since we can't realiably check for the window closing, we'll just check every 3 seconds
    if (!windowHandle) {
      return;
    }
    if (!windowHandle.closed) {
      // Assume this was called due to re-direct so switch to polling for the window being closed
      setTimeout(() => {
        checkConnectionWindowClose(windowHandle);
      }, 3000);
      return;
    }
    setAccountConnecting(false);
    setIsLoading(true);
    const sessionToken = await getSessionToken(appBridge);
    let success = false;
    try {
      const { brand, brandConnected } = await authenticateShopifySessionToken(
        sessionToken,
        false
      );
      if (brand && brandConnected) {
        // We are connected
        success = true;
        setConnectedBrand(brand);
      }
    } catch (e) {
      console.error(e);
    }
    setShopifyConnectFlag(success);
    setConnectFailed(!success);
    setIsLoading(false);
  };

  const onDisconnectAccount = () => {
    setDisconnectModalOpen(true);
  };

  const onDisconnectConfirmed = async () => {
    setDisconnectModalOpen(false);
    setIsLoading(true);
    const sessionToken = await getSessionToken(appBridge);
    let success;
    try {
      success = await diconnectShopify(sessionToken, false);
    } catch (e) {
      console.error(e);
    }
    if (success) {
      setShopifyConnectFlag(false);
      setConnectedBrand(null);
    } else {
      setDisconnectFailed(true);
    }
    setIsLoading(false);
  };

  const renderUnconnectedPage = () => {
    return (
      <Layout>
        <Card sectioned>
          <EmptyState
            heading="The new future of sales channels for brands, influencers, and agencies"
            action={{
              content: "Connect to fomopromo",
              onAction: () => onAccountConnectionOpen(),
              disabled: accountConnecting,
              loading: accountConnecting,
            }}
            image="/images/fomoPromo_logo.png"
            imageContained={true}
            footerContent={
              <FooterHelp>
                Learn more about{" "}
                <Link external url="https://fomopromo.com">
                  fomopromo
                </Link>
              </FooterHelp>
            }
          >
            <p>
              Connect your store to an existing brand account in fomopromo or
              signup and create a new one to begin managing campaigns that
              promote your Shopify products.
            </p>
          </EmptyState>
        </Card>
        {accountConnecting && (
          <Popout
            id="shopify-connect-dialog"
            url={`${process.env.REACT_APP_WEBAPP_URL}/login?source=shopify&shop=${shop}`}
            reactDom={ReactDOM}
            onCreate={onAccountConnectionCreate}
            options={{ width: 1024, height: 832 }}
          />
        )}
        {connectFailed && (
          <Toast
            error
            content="Connection did not complete. If you believe this was in error, please check your connection and try again."
            onDismiss={() => setConnectFailed(false)}
            duration={10000}
          />
        )}
        {verifyCheckFailed && (
          <Toast
            error
            content="Your store must be verified by Shopify before it can be connected to a fomopromo account."
            onDismiss={() => setVerifyCheckFailed(false)}
            duration={10000}
          />
        )}
      </Layout>
    );
  };

  const renderConnectedPage = () => {
    const name = connectedBrand?.name || "Unknown Brand";
    const image = connectedBrand?.imageUrl;
    const initials = name
      .split(" ")
      .map((n) => n[0])
      .join("");

    return (
      <Layout>
        <Layout.AnnotatedSection
          id="fomopromoConnection"
          title="Brand Account"
          description="Your brand account in fomopromo is connected to your Shopify store."
        >
          <Card title="Connected Brand">
            <Card.Section>
              <Stack distribution="equalSpacing">
                <Stack alignment="center">
                  <Avatar initials={initials} source={image} />
                  <p>{name}</p>
                </Stack>
                <Button outline onClick={() => onDisconnectAccount()}>
                  Disconnect
                </Button>
              </Stack>
            </Card.Section>
            <Card.Section subdued>
              <p>
                Disconnecting your brand could disrupt any active campaigns that
                are currently running in fomopromo.
              </p>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          id="fomopromoInfo"
          title="Campaigns"
          description="Connect to influencers and manage campaigns to promote your Shopify products."
        >
          <CalloutCard
            title="fomopromo"
            primaryAction={{
              content: "Manage Campaigns",
              external: true,
              url: `${process.env.REACT_APP_WEBAPP_URL}/campaigns`,
            }}
            illustration="/images/fomoPromo_logo.png"
          >
            <p>
              You can create and manage campaigns in the fomopromo web or mobile
              app.
            </p>
          </CalloutCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          id="publishing"
          title="Publishing"
          description="Products that are synced to fomopromo or that need additional attention will be displayed here."
        >
          <Card
            sectioned
            title="Product status"
            actions={[
              { content: "Manage availability", onAction: onSelectProducts },
            ]}
          >
            <Card.Section>
              <TextContainer>
                <Banner title="Product availability" status="info">
                  <p>
                    Once products are available they will be selectable in the
                    campaign creation process. Please make any product
                    collections you have defined available as well.
                  </p>
                  <br />
                  <p>
                    It may take some time for products to show up in fomopromo.
                  </p>
                </Banner>
                <p>
                  <b>{products}</b> Products and <b>{collections}</b>{" "}
                  Collections are available for use in campaigns
                </p>
              </TextContainer>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection>
          <Card title="Terms and conditions">
            <Card.Section>
              <p>
                Use of the fomopromo service is guided by the{" "}
                <Link
                  external
                  url="https://www.fomopromo.com/terms-of-service/"
                >
                  Terms of Service
                </Link>{" "}
                and is subject to the fomopromo{" "}
                <Link external url="https://www.fomopromo.com/privacy-policy/">
                  Privacy Policy
                </Link>
              </p>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <FooterHelp>
          Learn more about{" "}
          <Link external url="https://fomopromo.com">
            fomopromo
          </Link>
        </FooterHelp>
        <Modal
          open={disconnectModalOpen}
          onClose={() => setDisconnectModalOpen(false)}
          title="Disconnect your brand"
          primaryAction={{
            content: "Disconnect",
            onAction: onDisconnectConfirmed,
            distructive: true,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => setDisconnectModalOpen(false),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Your products will no longer be available to run campaigns in
                fomopromo. Any actively running campaigns may be disrupted
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        {disconnectFailed && (
          <Toast
            error
            content="Disconnect failed. We were unable to disconnect your store at this time. Please try again or contact support."
            onDismiss={() => setDisconnectFailed(false)}
            duration={10000}
          />
        )}
      </Layout>
    );
  };

  return (
    <>
      <AppProvider i18n={enTranslations}>
        <Frame>
          {isLoading && <Loading />}
          {!isLoading && (
            <Page>
              {!isConnected && renderUnconnectedPage()}
              {isConnected && renderConnectedPage()}
            </Page>
          )}
        </Frame>
      </AppProvider>
    </>
  );
};

export default ShopifyAccount;
