import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ContextProvider } from "./context";
import { ShopifyFinalize, ShopifyRegister, Error404 } from "./components";

function App() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <Routes>
          <Route path="/register" element={<ShopifyRegister />} />
          <Route path="/finalize" element={<ShopifyFinalize />} />
          <Route path="/" element={<Navigate to="/register" replace />} />
          <Route element={<Error404 />} />
        </Routes>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
