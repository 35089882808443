import styles from "./Error404.module.scss";

export default function WrongUrlRender() {
  const returnHomepage = () => {
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };
  return (
    <div className={styles.container}>
      <div className={styles.logoDiv}>
        <a href={`${process.env.PUBLIC_URL}/`}>
          <img
            className={styles.logo}
            src={`${process.env.PUBLIC_URL}/images/FomoPromo_logo__primary_color.png`}
            alt="0"
          />
        </a>
      </div>
      <div className={styles.blobDiv}>
        <img
          className={styles.blob}
          src={`${process.env.PUBLIC_URL}/images/Blob.svg`}
          alt="0"
        />
        <img
          className={styles.blob404}
          src={`${process.env.PUBLIC_URL}/images/404.svg`}
          alt="0"
        />
        <span className={styles.oopsText}>
          Oops! The page you are looking for does not exist.
        </span>
        <button className={styles.returnButton} onClick={returnHomepage}>
          Return home
        </button>
      </div>
    </div>
  );
}
