import React, { useEffect, useContext, useState } from "react";
import { RootContext } from "../context";
import { getPermissionUrl, isIframe } from "../utils";
import { useQuery } from "../hooks";

import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge-utils";

import ShopifyErrorDialog from "./ShopifyErrorDialog";
import ShopifyModal from "./ShopifyModal";
import ShopifyAccount from "./ShopifyAccount";

import {
  authenticateShopifySessionToken,
  connectBrandAndShop,
} from "../graphQL";

const close = () => {
  window.location.reload();
};

const ShopifyRegister = () => {
  const [loading, setLoading] = useState(true);
  const [showShopModal, setShowShopModal] = useState(false);
  const [shopConnected, setShopConnected] = useState(false);
  const [error, setError] = useState({});
  const [shop, setShop] = useState(null);
  const [connectedBrand, setConnectedBrand] = useState(null);
  const [appBridge, setAppBridge] = useState(null);
  const query = useQuery();

  const { setShopifyHost, setShopifyConnectFlag, shopifyHost } =
    useContext(RootContext);
  const shopUrl = query.get("shop");
  const host = query.get("host");

  useEffect(() => {
    (async () => {
      let fomopromoHost = window.location.host;
      if (!shopUrl) {
        setShowShopModal(true);
        return;
      }
      setShop(shopUrl.slice(0, shopUrl.length - 14));

      try {
        // Are we embedded in shopify?
        if (isIframe()) {
          const shopifyRawHost = host || shopifyHost;
          const app = createApp({
            apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
            host: shopifyRawHost,
          });
          setAppBridge(app);

          // Attempt to authenticate using a session token.
          // If the user is not authorized, push them through the oauth flow.
          const sessionToken = await getSessionToken(app);
          try {
            const { tokenExists, brand, brandConnected } =
              await authenticateShopifySessionToken(sessionToken);

            // Shop has not been connected to the app
            if (!tokenExists) {
              // Authentication was a failure, prepare for shopify oauth flow
              // Set shopify host to redirect back to shopify admin
              // since auth was initated from there in this case.
              setShopifyHost(shopifyRawHost);

              const shopPath = Buffer.from(shopifyRawHost, "base64").toString();
              const permissionUrl = getPermissionUrl(shopPath, fomopromoHost);
              return Redirect.create(app).dispatch(
                Redirect.Action.REMOTE,
                permissionUrl
              );
            }
            let connected = brandConnected;

            if (brand) {
              if (!connected) {
                await connectBrandAndShop(shopUrl, brand.id);
                connected = true;
              }
              setConnectedBrand(brand);
            }

            // This will be used by the account component
            setShopifyConnectFlag(connected);
            setLoading(false);
            setShopConnected(true);
          } catch (e) {
            console.error(e);
            const { errorInfo, message } = e?.errors?.[0] || {};
            const { nonFieldErrors } = errorInfo || {};
            const title = "Authentication Error";
            let text = message || "";
            if ((nonFieldErrors?.length || 0) > 0) {
              text = nonFieldErrors.join(" ,");
            } else if (nonFieldErrors) {
              text = nonFieldErrors[0];
            }
            setError({ title, text });
          }
        } else {
          const permissionUrl = getPermissionUrl(
            `${shopUrl}/admin`,
            fomopromoHost
          );
          window.location.href = permissionUrl;
        }
      } catch (e) {
        console.error(e);
        setError({ title: "Unknown Error", text: e.message });
      }
    })();
  }, [host, setShopifyConnectFlag, setShopifyHost, shopUrl, shopifyHost]);

  return (
    <>
      <ShopifyModal
        open={showShopModal}
        onClose={() => setShowShopModal(false)}
      />
      <ShopifyErrorDialog
        title={error?.title}
        text={error?.text}
        open={error?.title || false}
        close={close}
      />
      {shopConnected && (
        <ShopifyAccount
          shop={shop}
          brand={connectedBrand}
          appBridge={appBridge}
          loading={loading}
        />
      )}
    </>
  );
};

export default ShopifyRegister;
