import React from "react";
import styles from "./ShopifyLoader.module.scss";
import SVG from "react-inlinesvg";
import Dialog from "@material-ui/core/Dialog";

const ShopifyLoading = () => {
  return (
    <SVG
      className={styles.svgContainer}
      src={`${process.env.PUBLIC_URL}/images/loading.svg`}
    />
  );
};

const ShopifyLoader = () => {
  return (
    <Dialog open={true}>
      <div className={styles.mainContainer}>
        <div>
          <ShopifyLoading />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.heading}>We're connecting your shop</div>
          <div className={styles.text1}>
            Hang tight! We're connecting your shop. This shouldn't take more
            than a few seconds.
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default ShopifyLoader;
