//  getChannelId,
//  getIntegrationStatus,
//  getPermissionUrl,

import { gql } from "graphql-tag";
import { API } from "aws-amplify";

const finalizeShopify = gql`
  mutation ShopifyFinalize($queryString: String!) {
    shopifyFinalize(input: { queryString: $queryString }) {
      ExistingBrand
    }
  }
`;

const shopifyConnectToBrand = gql`
  mutation ShopifyConnectToBrand($input: ShopifyConnectToBrandInput!) {
    shopifyConnectToBrand(input: $input)
  }
`;

const shopifySessionAuth = gql`
  mutation ShopifySessionAuth($token: String!) {
    shopifySessionAuth(token: $token) {
      Exists
      Brand {
        id
        name
        imageUrl
      }
      Connected
    }
  }
`;

const shopifySessionDisconnect = gql`
  mutation ShopifySessionDisconnect($token: String!) {
    shopifySessionDisconnect(token: $token)
  }
`;

const shopifySessionStatus = gql`
  query ShopifySessionStatus($token: String!) {
    shopifySessionStatus(token: $token) {
      collections
      products
    }
  }
`;

const shopifyChannelId = gql`
  query shopifyChannelId($token: String!) {
    shopifyChannelId(token: $token)
  }
`;

const isShopVerified = gql`
  query isShopVerified($shop: String!) {
    isShopVerified(shop: $shop)
  }
`;

/**
 * Authenticates fomopromo user with shopify.
 *
 * @param{string} queryString - The query string from redirect
 *
 */
export const authenticateWithShopify = async (queryString) => {
  const {
    data: {
      shopifyFinalize: { ExistingBrand },
    },
  } = await API.graphql({
    query: finalizeShopify,
    variables: { queryString },
    authMode: "API_KEY",
  });

  return { brandExists: ExistingBrand };
};

/**
 * Authenticates to fomopromo using a shopify session token
 */
export const authenticateShopifySessionToken = async (token) => {
  const {
    data: {
      shopifySessionAuth: { Exists, Brand, Connected },
    },
  } = await API.graphql({
    query: shopifySessionAuth,
    variables: { token },
    authMode: "API_KEY",
  });

  return {
    tokenExists: Exists,
    brand: Brand,
    brandConnected: Connected,
  };
};

export const checkShopVerified = async (shop, isAuthenticated = false) => {
  try {
    const verified = await API.graphql({
      query: isShopVerified,
      variables: { shop },
      ...(!isAuthenticated && { authMode: "API_KEY" }),
    });
    return verified;
  } catch (e) {
    console.error("Failed checking shop verification: ", e);
    return false;
  }
};

/**
 * Connects shop to brand
 */
export const connectBrandAndShop = async (shop, brandId) => {
  const {
    data: { shopifyConnectToBrand: result },
  } = await API.graphql({
    query: shopifyConnectToBrand,
    variables: { input: { shop, brandId } },
    authMode: "API_KEY",
  });
  return result;
};

/**
 * Removes shop/brand connection
 */
export const diconnectShopify = async (token) => {
  const {
    data: { shopifySessionDisconnect: result },
  } = await API.graphql({
    query: shopifySessionDisconnect,
    variables: { token },
    authMode: "API_KEY",
  });

  return result;
};

export const getChannelId = async (token) => {
  const {
    data: { shopifyChannelId: channelId },
  } = await API.graphql({
    query: shopifyChannelId,
    variables: { token },
    authMode: "API_KEY",
  });

  return channelId;
};

export const getIntegrationStatus = async (token) => {
  const {
    data: { shopifySessionStatus: status },
  } = await API.graphql({
    query: shopifySessionStatus,
    variables: { token },
    authMode: "API_KEY",
  });

  return status;
};
