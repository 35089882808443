import React, { useEffect, useState } from "react";
import { localStorage } from "../utils";
export const RootContext = React.createContext();

const shopifyHostFromSession = localStorage.getItem("shopifyHost") || null;

export const ContextProvider = ({ children }) => {
  const [shopifyConnectFlag, setShopifyConnectFlag] = useState(null);
  const [shopifyHost, setShopifyHost] = useState(shopifyHostFromSession);

  useEffect(() => {
    if (shopifyHost == null) {
      localStorage.removeItem("shopifyHost");
    } else {
      localStorage.setItem("shopifyHost", shopifyHost);
    }
  }, [shopifyHost]);

  const defaultContext = {
    shopifyConnectFlag,
    shopifyHost,
    setShopifyConnectFlag,
    setShopifyHost,
  };

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};