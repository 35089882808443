var _localStorage;
try {
  _localStorage = window.localStorage;
} catch {
  console.log("localStorage not available, overriding");
}
if (!_localStorage) {
  _localStorage = (function () {
    var data = {};

    return {
      setItem: function (key, item) {
        data[key] = item;
      },
      getItem: function (key) {
        return data[key] === undefined ? null : data[key];
      },
      removeItem: function (key) {
        delete data[key];
      },
      clear: function () {
        data = {};
      },
    };
  })();
}
export const localStorage = _localStorage;

export const isIframe = () => {
  return window.top !== window.self;
};

/**
 * Construct a shopify oauth URL for requesting authorization
 *
 * @param {String} shopPath - path to shop that is being authorized
 * @param {String} redirectHost - URL to redirect to after successful authorization
 * @param {Boolean} online - whetner a user-specific token is being requested
 * @returns {String}
 */
export const getPermissionUrl = (shopPath, redirectHost, online) => {
  const redirectUri = encodeURIComponent(`https://${redirectHost}/finalize`);
  const scopes = [
    "read_products",
    "read_product_listings",
    // "read_merchant_approval_signals",
    // "write_gift_cards",
    "write_discounts",
    "write_checkouts",
    "read_orders",
    "unauthenticated_read_product_listings",
    "unauthenticated_read_product_tags",
    "unauthenticated_write_checkouts",
    "unauthenticated_write_customers",
    "unauthenticated_read_customer_tags",
    "unauthenticated_read_content",
  ];

  return (
    `https://${shopPath}/oauth/authorize?client_id=${
      process.env.REACT_APP_SHOPIFY_API_KEY
    }&scope=${scopes.join("%2C")}&redirect_uri=${redirectUri}` +
    (online ? "&grant_options%5B%5D=per-user" : "")
  );
};
